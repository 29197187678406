.custom-placeholder::placeholder {
    color: #ccc;
}

.custom-placeholder:hover::placeholder {
    color: #000;
}

.custom-placeholder-icon {
    color: #ccc;
}

.custom-placeholder-icon:hover {
    color: #000;
}